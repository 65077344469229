/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb
require.context('../images/biz', true);

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'bootstrap/dist/css/bootstrap'
import 'admin-lte/dist/css/AdminLTE'
import 'admin-lte/dist/css/skins/skin-blue-light'

import 'bootstrap/dist/js/bootstrap'
import 'admin-lte/dist/js/adminlte'
import '../styles/biz/main'
import Rails from 'rails-ujs';
Rails.start();
